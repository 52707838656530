export const tkList = [
  { id: 1, tkname: "Деловые Линии" },
  { id: 2, tkname: "ПЭК" },
  { id: 3, tkname: "Байкал" },
  { id: 4, tkname: "Мэйджик Транс" },
  { id: 5, tkname: "КИТ" },
];
export const ourFirmsList = [
  {
    id: 11,
    name: "АО Компонент",
    inn: "7802233316",
    kpp: "780201001",
    tel: "(812)448-08-98",
  },
  {
    id: 22,
    name: "АО ФТИ-Оптроник",
    inn: "7802059121",
    kpp: "780201001",
    tel: "(812)448-08-60",
  },
  {
    id: 33,
    name: "ООО ФТИ-Оптроник",
    inn: "7802830545",
    kpp: "780101001",
    tel: "(812)448-08-60",
  },
  {
    id: 44,
    name: "ООО ТехСвязьПоставка",
    inn: "7804266388",
    kpp: "781401001",
    // tel: "(812)597-76-47"
    tel: "-",
  },
  {
    id: 55,
    name: "Частное лицо",
    inn: "-",
    kpp: "-",
  },
];

export const payerSender = "Отправитель";
export const payerReceiver = "Получатель";
export const payerThirdParty = "Третье лицо";

export const typeAddr = "До адреса";
export const typeTerm = "До терминала";
export const INFO_UNAVAILABLE = "Информация недоступна";
