import config from "../utils/config.json";

class DaDataApi {
  constructor(options) {
    this.baseURL = options.baseURL;
    this.headers = options.headers;
  }

  getByINN(query) {
    return fetch(this.baseURL + "/party", {
      method: "POST",
      mode: "cors",
      headers: this.headers,
      body: JSON.stringify({ query: query }),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject(res);
    });
  }

  getByAddress(query) {
    return fetch(this.baseURL + "/address", {
      method: "POST",
      mode: "cors",
      headers: this.headers,
      body: JSON.stringify({ query: query }),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject(res);
    });
  }
}

const options = {
  baseURL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest",
  method: "POST",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Token " + config.DA_TOKEN,
  },
};

const daApi = new DaDataApi(options);
export default daApi;
