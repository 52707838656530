import "./Popup.css";
import { useForm } from "react-hook-form";

function Popup({ hidden, blockScroll, setCurrentUser }) {
  const handleSave = (data) => {
    const user = {
      name: data.name,
      phone: data.phone,
      addPhone: data.addPhone,
    };
    localStorage.setItem("user", JSON.stringify(user));
    setCurrentUser(user);
    document.getElementById("popupID").classList.add("popup__hidden");
    window.removeEventListener("scroll", blockScroll);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => handleSave(data);
  return (
    <div id='popupID' className={`popup ${hidden ? "popup__hidden" : ""}`}>
      <form onSubmit={handleSubmit(onSubmit)} className='popup__form'>
        <input
          className='form__input '
          type='text'
          placeholder='Имя и фамилия'
          {...register("name", { required: true, min: 2 })}
        />

        <input
          className='form__input '
          type='tel'
          placeholder='Телефон (без +7, без тире и пробелов)'
          {...register("phone", {
            required: true,
            pattern: {
              value: /^[0-9]+$/,
              message: "Присутствует неверный символ",
            },
            maxLength: {
              value: 10,
              message:
                "10 символов же в номере телефона без +7, без тире и пробелов",
            },
            minLength: {
              value: 10,
              message:
                "10 символов же в номере телефона без +7, без тире и пробелов",
            },
          })}
        />
        <span
          className={`form__error ${
            errors?.phone ? "form__error_visible" : ""
          }`}
          id='phone-error'
        >
          {errors?.phone?.message}
        </span>

        <input
          className='form__input '
          type='tel'
          placeholder='Твой добавочный номер'
          {...register("addPhone", {
            required: true,
            pattern: {
              value: /^[0-9]+$/,
              message: "Присутствует неверный символ",
            },
            maxLength: {
              value: 3,
              message: "Добавочный твой из трёх цифр состоит",
            },
            minLength: {
              value: 3,
              message: "Добавочный твой из трёх цифр состоит",
            },
          })}
        />
        <span
          className={`form__error ${
            errors?.addPhone ? "form__error_visible" : ""
          }`}
          id='addPhone-error'
        >
          {errors?.addPhone?.message}
        </span>

        <input type='submit' className='form__send' />
      </form>
    </div>
  );
}

export default Popup;
