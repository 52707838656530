import "./App.css";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { jsPDF } from "jspdf";
import font from "./utils/Inter-Regular-normal";
import font2 from "./utils/Inter-Bold-normal";

import React, { useState, useEffect } from "react";
import daApi from "./utils/DaData";

import {
  tkList,
  ourFirmsList,
  payerReceiver,
  payerSender,
  payerThirdParty,
  typeAddr,
  typeTerm,
  INFO_UNAVAILABLE,
} from "./utils/constants";
import Popup from "./components/Popup/Popup";

function App() {
  const [hidden, setHidden] = useState(false);
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setHidden(true);
      setCurrentUser(JSON.parse(localStorage.getItem("user")));
      window.removeEventListener("scroll", blockScroll);
    } else {
      setHidden(false);
      window.addEventListener("scroll", blockScroll);
    }
  }, [hidden]);

  function blockScroll() {
    window.scrollTo(0, 0);
  }

  const [sender, setSender] = useState(ourFirmsList[0]);

  const [receiver, setReceiver] = useState({});
  const [payerInfo, setPayerInfo] = useState(payerReceiver);
  // const [addressType, setAddressType] = useState(typeTerm);
  const [suggestions, setSuggestions] = React.useState([]);
  const [tpSuggestions, setTPSuggestions] = React.useState([]);
  const [addressSuggestions, setAddressSuggestions] = React.useState([]);

  let verticalOffset = 15;

  const {
    register,
    unregister,
    control,
    formState: { errors, isValid, isSubmitSuccessful },
    handleSubmit,
    watch,
    reset,
    setValue,
    // getValues
  } = useForm({
    mode: "onChange",
    defaultValues: {
      // type: "firm",
      // post_type: "terminal",
      person: false,
      tk_name: tkList[0].tkname,
      sender_firm: ourFirmsList[0].name,
      address: "",
      receiver: "",
      payer: payerReceiver,
      addrType: typeTerm,
    },
    shouldUnregister: true,
  });

  const person = watch("person");
  const payerWatchable = watch("payer");

  const addOffset = () => {
    verticalOffset += 8;
  };

  const onSubmit = (data) => {
    const lineSplitter = (line) => {
      let output = "";
      let counter = 1;
      line.split(" ").forEach((item, index) => {
        if ((output + item).length / counter < 50) {
          if (index === 0) output += item;
          else output += " " + item;
        } else {
          counter += 1;
          output += " \n" + item;
        }
      });
      return { output: output, counter: counter };
    };

    // console.log(data);

    // const doc = new jsPDF("p", "pt", "a4", true);
    const doc = new jsPDF();
    // doc.setFont("Inter-Regular");
    doc.setFont("Inter-Bold");
    doc.setFontSize(30);
    const fileName = `${data.tk_name}_плат${data.payer}`;

    doc.text(`${data.tk_name}`, 100, verticalOffset, { align: "center" });

    doc.setFontSize(15);
    addOffset();

    if (sender.name === "АО Компонент") {
      doc.setFont("Inter-Regular");
      doc.text(`Отправитель: ${sender.name}`, 10, verticalOffset);
    } else {
      doc.text(`Отправитель: ${sender.name}`, 10, verticalOffset);
      doc.setFont("Inter-Regular");
    }
    addOffset();
    doc.setFont("Inter-Regular");
    if (sender.name !== "Частное лицо") {
      doc.text(`ИНН: ${sender.inn}`, 10, verticalOffset);
      addOffset();
      doc.text(`КПП: ${sender.kpp}`, 10, verticalOffset);
      addOffset();
      const phone =
        "+7(" +
        currentUser.phone.slice(0, 3) +
        ")" +
        currentUser.phone.slice(3, 6) +
        "-" +
        currentUser.phone.slice(6, 8) +
        "-" +
        currentUser.phone.slice(8);

      doc.text(
        `Менеджер: ${currentUser.name} ${phone}, ${sender.tel} (доб. ${currentUser.addPhone})`,
        10,
        verticalOffset
      );
      addOffset();
    }
    doc.text(
      `_______________________________________________________`,
      10,
      verticalOffset
    );
    addOffset();
    let line = "";

    if (!person) {
      line = lineSplitter(`Получатель: ${receiver.name}`);
      doc.text(line.output, 10, verticalOffset);
      for (let i = 0; i < line.counter; i++) addOffset();

      doc.text(`ИНН: ${receiver.inn}`, 10, verticalOffset);
      addOffset();
      doc.text(`КПП: ${receiver.kpp}`, 10, verticalOffset);
      addOffset();

      line = lineSplitter(`Юр. адрес: ${receiver.address}`);

      doc.text(line.output, 10, verticalOffset);
      for (let i = 0; i < line.counter; i++) addOffset();
    } else {
      line = lineSplitter(`Получатель: ${data.receiverPerson}`);
      doc.text(line.output, 10, verticalOffset);
      for (let i = 0; i < line.counter; i++) addOffset();
    }

    doc.text(
      `_______________________________________________________`,
      10,
      verticalOffset
    );
    addOffset();
    line = lineSplitter(`Адрес: ${data.address}`);
    doc.text(line.output, 10, verticalOffset);
    for (let i = 0; i < line.counter; i++) addOffset();

    doc.text(`Доставка: ${data.addrType}`, 10, verticalOffset);
    addOffset();
    line = lineSplitter(`Телефон(ы) получателя: ${data.tel}`);
    doc.text(line.output, 10, verticalOffset);
    for (let i = 0; i < line.counter; i++) addOffset();

    doc.text(
      `_______________________________________________________`,
      10,
      verticalOffset
    );
    addOffset();

    doc.text(`Плательщик: ${data.payer}`, 10, verticalOffset);
    addOffset();

    if (payerWatchable === payerThirdParty) {
      line = lineSplitter(`${payerInfo.payerName}`);
      doc.text(line.output, 10, verticalOffset);
      for (let i = 0; i < line.counter; i++) addOffset();

      doc.text(`ИНН: ${payerInfo?.payerINN}`, 10, verticalOffset);
      addOffset();
      doc.text(`КПП: ${payerInfo?.payerKPP}`, 10, verticalOffset);
      addOffset();
    }
    addOffset();
    addOffset();
    doc.setFontSize(40);
    if (data.avia) {
      doc.text("АВИА", 100, verticalOffset, { align: "center" });
      addOffset();
      addOffset();
    }
    if (data.express) {
      doc.text("ЭКСПРЕСС", 100, verticalOffset, { align: "center" });
      addOffset();
      addOffset();
    }
    if (data.hardPackaging) {
      doc.text("Ж/У", 100, verticalOffset, { align: "center" });
      addOffset();
      addOffset();
    }
    doc.setFontSize(15);

    line = lineSplitter(`Комментарии: ${data.comments}`);
    data.comments && doc.text(line.output, 10, verticalOffset);
    for (let i = 0; i < line.counter; i++) addOffset();

    // doc.save(`${fileName}.pdf`);
    window.open(doc.output("bloburl"));
  };

  const handleChangeSender = (e) => {
    const senderIns = ourFirmsList.filter(
      (item) => item.name === e.target.value
    );
    setSender(senderIns[0]);
  };

  const handleSearchClients = (e) => {
    console.log(e.target.value);
    if (e.target.value.includes("	")) {
      const newValue = e.target.value.replaceAll("	", "");
      setValue("receiver", newValue);
    }
    daApi
      .getByINN(e.target.value)
      .then((data) => {
        if (e.target.id === "receiver") setSuggestions(data.suggestions);
        else setTPSuggestions(data.suggestions);
        // console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const suggestAddress = (e) => {
    // console.log(e.target.value);
    daApi
      .getByAddress(e.target.value)
      .then((res) => {
        setAddressSuggestions(res.suggestions);
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const handleChangePayer = (e) => {
    // console.log(payerWatchable);
    // setPayer(e.target.value);
    setValue("payer", e.target.value);
  };

  const handleChangeAddress = (e) => {
    // console.log(e.target);
    // setAddressType(e.target.value);
    setValue("addrType", e.target.value);
  };

  const handleSetReceiver = (e, item, person) => {
    // console.log(item);
    // if(!person) {}
    setReceiver({
      name: item?.value || INFO_UNAVAILABLE,
      inn: item?.data?.inn || INFO_UNAVAILABLE,
      kpp: item?.data?.kpp || INFO_UNAVAILABLE,
      city: item?.data?.address?.data?.city_with_type || INFO_UNAVAILABLE,
      address: item?.data?.address?.value || INFO_UNAVAILABLE,
    });
    setSuggestions([]);
    setValue(
      "receiver",
      `${item?.value || INFO_UNAVAILABLE} | ${
        item?.data?.inn || INFO_UNAVAILABLE
      } | ${item?.data?.address?.data?.city_with_type || INFO_UNAVAILABLE}`
    );
  };

  const handleSetPayerTP = (e, item) => {
    // console.log(item);
    setPayerInfo({
      payerName: item.value,
      payerINN: item.data.inn,
      payerKPP: item.data.kpp,
    });
    setValue("payerTP", item.value);
    setTPSuggestions([]);
  };

  const handleSetAddress = (e, item) => {
    // console.log(item.value);
    setValue("address", item.value);
    setAddressSuggestions([]);
  };

  return (
    <div className='App'>
      <Popup
        hidden={hidden}
        blockScroll={blockScroll}
        setCurrentUser={setCurrentUser}
      />
      <header className='App-header'>
        <h1>Сделай уже нормально</h1>
      </header>
      <form
        className='form'
        noValidate='novalidate'
        onSubmit={handleSubmit(onSubmit)}
        autoComplete='off'
      >
        <h3>Выбор ТК</h3>
        <select
          className='form__select form__select_type-tk'
          {...register("tk_name", {
            required: "Обязательное поле",
          })}
        >
          {tkList &&
            tkList.map((tk, i) => {
              return (
                <option key={`${tk.id}_${i}`} value={tk.tkname}>
                  {tk.tkname}
                </option>
              );
            })}
        </select>
        <h3>Фирма-отправитель</h3>
        <select
          className='form__select form__select_type-our_firm'
          {...register("sender_firm")}
          onChange={(e) => handleChangeSender(e)}
        >
          {ourFirmsList &&
            ourFirmsList.map((firm, i) => {
              return (
                <option key={firm.id} value={firm.name}>
                  {firm.name}
                </option>
              );
            })}
        </select>
        <h3>Кому везём?</h3>
        <label htmlFor='person' className=''>
          <div className='checkbox-wrapper-8'>
            <input
              className='tgl tgl-skewed'
              id='person'
              type='checkbox'
              {...register("person", {})}
            />
            <label
              className='tgl-btn'
              data-tg-off='НЕТ'
              data-tg-on='ДА'
              htmlFor='person'
            ></label>
            Получатель - частное лицо
          </div>
        </label>
        <label htmlFor='receiver' className='form__label'>
          {person
            ? `ФИО получателя, паспортные данные`
            : `ИНН / Название фирмы`}
        </label>

        <div className='container'>
          {!person && (
            <input
              id='receiver'
              type='text'
              name='receiver'
              autoComplete='new-password'
              {...register("receiver", {
                required: "Поле обязательно к заполнению",
                minLength: {
                  value: 1,
                  message: "Поле должно содержать от 1 до 800 символов",
                },
                maxLength: {
                  value: 800,
                  message: "Поле должно содержать от 1 до 800 символов",
                },
                onChange: (e) => handleSearchClients(e),
              })}
              className={`form__input ${
                errors?.receiver ? "form__input_type_error" : ""
              }`}
              placeholder='Искать клиентиков'
              required
            ></input>
          )}

          {person && (
            <input
              id='receiverPerson'
              type='text'
              name='receiverPerson'
              autoComplete='new-password'
              {...register("receiverPerson", {
                required: "Поле обязательно к заполнению",
                minLength: {
                  value: 1,
                  message: "Поле должно содержать от 1 до 800 символов",
                },
                maxLength: {
                  value: 800,
                  message: "Поле должно содержать от 2 до 800 символов",
                },
                onChange: (e) => {
                  setReceiver(e.target.value);
                },
              })}
              className={`form__input ${
                errors?.receiverPerson ? "form__input_type_error" : ""
              }`}
              placeholder='Введи сюда ФИО получателя'
              required
            ></input>
          )}

          <ul className='suggestions address__suggestions'>
            {suggestions?.map((item, index) => {
              return (
                <li
                  className='suggestions_item'
                  key={index}
                  onClick={(e) => handleSetReceiver(e, item)}
                >
                  {item?.value || INFO_UNAVAILABLE} |{" "}
                  {item?.data?.inn || INFO_UNAVAILABLE} |
                  {item?.data?.address?.data?.city_with_type ||
                    INFO_UNAVAILABLE}{" "}
                  | {item?.data?.address?.value || INFO_UNAVAILABLE}
                </li>
              );
            })}
          </ul>
          <span
            className={`form__error ${
              errors?.receiver ? "form__error_visible" : ""
            }`}
            id='receiver-error'
          >
            {errors?.receiver?.message}
          </span>
        </div>
        <label htmlFor='sameAddress' className=''>
          <div className='checkbox-wrapper-8'>
            <input
              className='tgl tgl-skewed'
              id='sameAddress'
              type='checkbox'
              {...register("sameAddress", {
                onChange: (e) => {
                  if (e.target.checked) {
                    setValue("address", receiver.address);
                  } else {
                    setValue("address", "");
                  }
                },
              })}
            />
            <label
              className='tgl-btn'
              data-tg-off='НЕТ'
              data-tg-on='ДА'
              htmlFor='sameAddress'
            ></label>
            Юридический адрес совпадает с адресом доставки
          </div>
        </label>

        <div className='shipmentTypeRadio'>
          <label htmlFor='payerReceiver' className='form__label'>
            Плательщик
          </label>

          <div className='checkbox-wrapper-18'>
            <div className='round'>
              <input
                type='radio'
                id='payerReceiver'
                value={payerReceiver}
                {...register("payer", {
                  onChange: (e) => handleChangePayer(e),
                })}
              />
              <label htmlFor='payerReceiver'></label>
            </div>
            <div>Получатель</div>
          </div>
          <div className='checkbox-wrapper-18'>
            <div className='round'>
              <input
                type='radio'
                id='payerSender'
                value={payerSender}
                {...register("payer", {
                  onChange: (e) => handleChangePayer(e),
                })}
              />
              <label htmlFor='payerSender'></label>
            </div>
            <div>Отправитель</div>
          </div>
          <div className='checkbox-wrapper-18'>
            <div className='round'>
              <input
                type='radio'
                id='payerThirdParty'
                value={payerThirdParty}
                {...register("payer", {
                  onChange: (e) => handleChangePayer(e),
                })}
              />
              <label htmlFor='payerThirdParty'></label>
            </div>
            <div>Третье лицо</div>
          </div>
        </div>

        {/* HERE */}
        {/* HERE */}
        {/* HERE */}
        {/* HERE */}
        {/* HERE */}

        <div className='container'>
          {payerWatchable === payerThirdParty && (
            <input
              id='payerTP'
              type='text'
              name='payerTP'
              autoComplete='new-password'
              {...register("payerTP", {
                required: "Поле обязательно к заполнению",
                minLength: {
                  value: 1,
                  message: "Поле должно содержать от 1 до 800 символов",
                },
                maxLength: {
                  value: 800,
                  message: "Поле должно содержать от 1 до 800 символов",
                },
                onChange: (e) => handleSearchClients(e),
              })}
              className={`form__input ${
                errors?.payerTP ? "form__input_type_error" : ""
              }`}
              placeholder='Поиск того, кто будет платить за банкет'
              required
            ></input>
          )}

          <ul className='suggestions address__suggestions'>
            {tpSuggestions?.map((item, index) => {
              return (
                <li
                  className='suggestions_item'
                  key={index}
                  onClick={(e) => handleSetPayerTP(e, item)}
                >
                  {item?.value || INFO_UNAVAILABLE} |{" "}
                  {item?.data?.inn || INFO_UNAVAILABLE} |
                  {item?.data?.address?.data?.city_with_type ||
                    INFO_UNAVAILABLE}{" "}
                  | {item?.data?.address?.value || INFO_UNAVAILABLE}
                </li>
              );
            })}
          </ul>
          <span
            className={`form__error ${
              errors?.payerTP ? "form__error_visible" : ""
            }`}
            id='payerTP-error'
          >
            {errors?.payerTP?.message}
          </span>
        </div>

        {/* HERE */}
        {/* HERE */}
        {/* HERE */}
        {/* HERE */}
        <br />
        <div className='addrTypeRadio'>
          <label htmlFor='addrTypeAddr' className='form__label'>
            Доставка
          </label>

          <div className='checkbox-wrapper-18'>
            <div className='round'>
              <input
                type='radio'
                id='addrTypeAddr'
                {...register("addrType", {
                  onChange: (e) => handleChangeAddress(e),
                })}
                value={typeAddr}
              />
              <label htmlFor='addrTypeAddr'></label>
            </div>
            <div>До адреса</div>
          </div>

          <div className='checkbox-wrapper-18'>
            <div className='round'>
              <input
                type='radio'
                id='addrTypeTerm'
                value={typeTerm}
                {...register("addrType", {
                  onChange: (e) => handleChangeAddress(e),
                })}
              />
              <label htmlFor='addrTypeTerm'></label>
            </div>
            <div>До терминала</div>
          </div>
        </div>
        <br />

        <div className='container' id='addressContainer'>
          <input
            autoComplete='new-password'
            type='text'
            required
            placeholder='Адрес получателя (если до терминала, просто введи город сюда)'
            {...register("address", {
              required: "Поле обязательно к заполнению",
              onChange: (e) => {
                suggestAddress(e);
              },
            })}
            className={`form__input ${
              errors?.address ? "form__input_type_error" : ""
            }`}
          />
          <span
            className={`form__error ${
              errors?.address ? "form__error_visible" : ""
            }`}
            id='address-error'
          >
            {errors?.address?.message}
          </span>
          <ul className='suggestions address__suggestions'>
            {addressSuggestions?.map((item, index) => {
              return (
                <li
                  className='suggestions_item'
                  key={index}
                  onClick={(e) => handleSetAddress(e, item)}
                >
                  {item.value}
                </li>
              );
            })}
          </ul>
        </div>
        <input
          required
          type='text'
          autoComplete='new-password'
          placeholder='Телефоны все сюда введи списком через зяпятую'
          {...register("tel", {
            required: "Поле обязательно к заполнению",
          })}
          className={`form__input ${
            errors?.tel ? "form__input_type_error" : ""
          }`}
        />
        <span
          className={`form__error ${errors?.tel ? "form__error_visible" : ""}`}
          id='tel-error'
        >
          {errors?.tel?.message}
        </span>

        <div className='opts'>
          <label htmlFor='hardPackaging' className=''>
            <div className='checkbox-wrapper-8'>
              <input
                className='tgl tgl-skewed'
                id='hardPackaging'
                type='checkbox'
                {...register("hardPackaging", {})}
              />
              <label
                className='tgl-btn'
                data-tg-off='БЕЗ Ж/У'
                data-tg-on='Ж/У'
                htmlFor='hardPackaging'
              ></label>
            </div>
          </label>

          <label htmlFor='express' className=''>
            <div className='checkbox-wrapper-8'>
              <input
                className='tgl tgl-skewed'
                id='express'
                type='checkbox'
                {...register("express", {})}
              />
              <label
                className='tgl-btn'
                data-tg-off='Не срочно'
                data-tg-on='Экспресс'
                htmlFor='express'
              ></label>
            </div>
          </label>
          <label htmlFor='avia' className=''>
            <div className='checkbox-wrapper-8'>
              <input
                className='tgl tgl-skewed'
                id='avia'
                type='checkbox'
                {...register("avia", {})}
              />
              <label
                className='tgl-btn'
                data-tg-off='Не авиа'
                data-tg-on='АВИА'
                htmlFor='avia'
              ></label>
            </div>
          </label>
        </div>

        <input
          type='text'
          autoComplete='new-password'
          placeholder='Дополнительные комментарии (необязательно)'
          {...register("comments", {})}
          className={`form__input ${
            errors?.comments ? "form__input_type_error" : ""
          }`}
        />
        <span
          className={`form__error ${
            errors?.comments ? "form__error_visible" : ""
          }`}
          id='comments-error'
        >
          {errors?.comments?.message}
        </span>

        <input
          className='form__send'
          type='submit'
          value='Сделать красивую накладную'
        />
        <input
          className='form__send form__reset'
          type='reset'
          value='Сбросить'
        />
      </form>
      <DevTool control={control} />
    </div>
  );
}

export default App;
